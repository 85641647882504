









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Title extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  small: boolean;
}
