






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ContentBox extends Vue {
  
  @Prop({ type: String, default: 'transparent' })
  background!: string;
  
  @Prop({ type: String, default: '10px 15px' })
  padding!: string;

}

